/* ----------------------------Handwritten fonts */

@font-face {
  font-family: "FunkySignature";
  font-weight: normal;
  font-style: normal;
  src: url("./FunkySignature.ttf") format("truetype");
}
@font-face {
  font-family: "WardahRounded";
  font-weight: normal;
  font-style: normal;
  src: url("./WardahRounded.ttf") format("truetype");
}
@font-face {
  font-family: "EdwardianScript";
  font-weight: normal;
  font-style: normal;
  src: url("./EdwardianScriptITC.ttf") format("truetype");
}


/*-----------------------------Serif (display) font*/

@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  src: url('DMSerifDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Serif Display';
  font-style: italic;
  font-weight: 400;
  src: url('DMSerifDisplay-Italic.ttf') format('truetype');
}


/*-----------------------------Sans serif font*/


@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 100;
  src: url('DMSans-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 100;
  src: url('DMSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 200;
  src: url('DMSans-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 200;
  src: url('DMSans-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 300;
  src: url('DMSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 300;
  src: url('DMSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  src: url('DMSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('DMSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 500;
  src: url('DMSans-MediumItalic.ttf') format('truetype');
} 

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 600;
  src: url('DMSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 600;
  src: url('DMSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('DMSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  src: url('DMSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 800;
  src: url('DMSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 800;
  src: url('DMSans-ExtraBoldItalic.ttf') format('truetype');
}


